<template>
  <ion-page>
    <ion-content>
      <div class="main">
        <div class="topBackground">
          <ion-text class="regular cWhite ion-text-center">
            <h1 class="ion-no-margin menuTitleFont">Mon Profil</h1>
          </ion-text>
        </div>
        <div class="vertical-align maxWidth">
          <ion-card>
            <ion-card-header>
              <ion-card-title color="dark" v-if="isPharmacist">
                Bienvenue <span class="underline">{{ firstName }} {{ lastName }}</span> dans votre espace personnel
              </ion-card-title>
              <ion-card-title v-else>
                Trop heureux de te connaître ❤️ <br/> <span class="underline">{{ firstName }} {{ lastName }}</span>,
                <span>{{ capitalize(status) }}</span>
              ️</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <div class="ion-margin" style="height: 95px;">
                <ion-card-title color="dark">
                  <img class="icon" height="55" width="55" src="../../public/assets/icon/phone.svg">
                </ion-card-title>
                <ion-label v-if="!isModifiedProfil" color="medium">
                  {{ phoneNumber }}
                </ion-label>
                <ion-label
                  v-else
                  @click="modifyPhone"
                  color="medium"
                  class="hoverable bottom-border"
                >
                  {{ phoneNumber }}
                </ion-label
                >
              </div>
              <div class="ion-margin" style="height: 95px;">
                <ion-card-title color="dark">
                  <img class="icon"  height="55" width="55" src="../../public/assets/icon/email.svg">
                </ion-card-title>
                <ion-label v-if="!isModifiedProfil" color="medium">
                  {{ email }}
                </ion-label>
                <ion-input
                        v-else
                        v-model="newMail"
                        :value="newMail"
                        class="bottom-border"
                />
              </div>
              <div class="ion-margin" style="height: 65px;" v-if="!isPharmacist">
                <ion-card-title color="dark" >IBAN</ion-card-title>
                <ion-label v-if="!isModifiedProfil" color="medium">
                  {{ iban }}
                </ion-label>
                <ion-input
                  v-else
                  v-model="newIban"
                  :value="newIban"
                  class="bottom-border"
                />
              </div>
              <div class="ion-margin" style="height: 65px;" v-if="!isPharmacist">
                <ion-card-title color="dark" >Mon CV</ion-card-title>
                <ion-label v-if="!isModifiedProfil" color="medium">
                  {{ this.newCVLink }}
                </ion-label>
                <ion-input
                  v-else
                  v-model="this.newCVLink"
                  :value="this.newCVLink"
                  class="bottom-border"
                />
              </div>
              <ion-button
                v-if="!isModifiedProfil"
                @click="modifyProfil"
                color="primary"
                class="sizedButton"
              >
                MODIFIER
              </ion-button>
              <ion-button v-else @click="confirmProfil" color="success" class="sizedButton">
                ENREGISTER
              </ion-button>
            </ion-card-content>
          </ion-card>
          <ion-button class="ion-margin bgRoseS sizedButton" @click="logout()">
            Me déconnecter
          </ion-button>
          <br/>
          <ion-button class="ion-margin bgRoseS" @click="destroy()">
            Supprimer mon compte
          </ion-button>
        </div>
      </div>
      <div v-if="!isDesktop" class="footerBackground">
        <img
          class="ion-margin-bottom logoFooter"
          src="../../public/assets/icon/logo-alloo-pharma.svg"
        />
      </div>
      <div v-else class="footerNotMobile">
        <img
          class="ion-margin-bottom logoFooter"
          src="../../public/assets/icon/alloopharma-logo.png"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { Dialog } from '@capacitor/dialog';
import store from "@/store";
import router from "@/router";
import {
  IonPage,
  IonContent,
  IonButton,
  IonText,
  IonCardTitle,
  IonCardHeader,
  IonCard,
  IonCardContent,
  IonInput,
  IonLabel
} from "@ionic/vue";
import {deleteAccount, signOut, updateProfile} from "@/services/api";

export default {
  name: "Profil",
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonText,
    IonCardTitle,
    IonCardHeader,
    IonCard,
    IonCardContent,
    IonInput,
    IonLabel
  },
  computed: {
    isDesktop: () => store.getters.isDesktop,
    status: () => store.getters.status,
    iban: () => store.getters.iban,
    CVLink: () => store.getters.cvLink,
    email: () => store.getters.emailUser,
    phoneNumber: () => store.getters.phoneNumber,
    firstName() {
      const firstName = store.getters.firstName;
      if (!firstName) return null;
      return firstName.charAt(0).toUpperCase() + firstName.slice(1);
    },
    lastName() {
      const lastName = store.getters.lastName;
      if (!lastName) return null;
      return lastName.charAt(0).toUpperCase() + lastName.slice(1);
    }
  },
  data() {
    return {
      newIban: store.getters.iban,
      newCVLink: store.getters.cvLink,
      newMail: store.getters.emailUser,
      isModifiedProfil: false,
      isPharmacist: store.getters.isPharmacist,
      sWantConfirmIban: false
    };
  },

  methods: {
    async askConfirmModifyIban() {
      this.isWantConfirmIban = false
      const resp = await Dialog.confirm({
      title: 'Demande de confirmation',
      message: 'Es-tu sûr de vouloir modifier les informations ?'
      });
      this.isWantConfirmIban = resp.value
    },
    logout() {
      Dialog.confirm({
        title: 'Demande de confirmation',
        message: 'Es-tu sûr de vouloir te déconnecter ?'
      }).then(({value}) => {
        if (value) {
          signOut();
          window.$crisp.push(["do", "session:reset"]);
          router.push("/connexion");
        }
      });
    },
    destroy() {
      Dialog.confirm({
        title: 'Demande de confirmation',
        message: 'Es-tu sûr de vouloir supprimer ton compte définitivement ?'
      }).then(({value}) => {
        if (value) {
          deleteAccount();
          window.$crisp.push(["do", "session:reset"]);
          router.push("/connexion");
        }
      });
    },
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    modifyProfil() {
      console.log(store.getters.cvLink)
      this.isModifiedProfil = true;
    },
    ibanHasChanged() { return (this.newIban !== this.iban && this.newIban?.trim()?.length > 0) },
    CVChanged() { return (this.newCVLink !== this.CVLink && this.newCVLink?.trim()?.length > 0) },
    emailHasChanged() { return (this.newMail !== this.email && this.newMail?.trim()?.length > 0) },
    async confirmProfil() {
      if (this.ibanHasChanged() || this.emailHasChanged() || this.CVChanged()) {
        if(this.ibanHasChanged)
          await this.askConfirmModifyIban()
        if(this.isWantConfirmIban)
          updateProfile({ userAttributes: { iban: this.newIban, cv_link: this.newCVLink} , email: this.newMail });
      }
      this.isModifiedProfil = false;
    },
    modifyPhone() {
      router.push("/confirmation-telephone");
    }
  }
};
</script>

<style scoped>
h1 {
  padding: 50px;
  margin-top: -20px;
}
h2 {
  margin-top: 20px !important;
  font-size: 20px !important;
}
.bottom-border {
  border-bottom: 1px solid;
}
.bottom-border:hover {
  border-bottom: 0px solid;
}
.vertical-align {
  margin-top: 50px;
}
.underline {
  text-decoration: underline;
}



.bgRoseS {
  --background: var(--new-blue);
}

</style>
